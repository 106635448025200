:root {
  --midnight: #08080d;
  --pure-white: #ffffff;
  --blue: #1954eb;
  --text-dark: #6b7280;
  --light-gray: #e5e7eb;
  --dark-gray: #374151;
  --disable-gray: #9ca3af;
  --grey-00: #ffffff;
  --grey-05: #f8f8f8;
  --grey-10: #f2f2f2;
  --grey-15: #f3f4f6;
  --grey-20: #d9d9d9;
  --grey-30: #bfbfbf;
  --grey-50: #6a6f72;
  --grey-70: #595959;
  --grey-80: #404040;
  --grey-90: #262626;
  --grey-100: #08080d;
  --green: #01ba61;
  --green-light: #e6f8ef;
  --green-dark: #017b40;
  --red: #df0403;
  --red-light: #fdf2f2;
  --red-dark: #ba1c1b;
  --orange: #ffac32;
  --orange-light: #fff7ea;
  --orange-dark: #df8604;
  --blue-light: #f6f8fe;
  --blue-dark: #0839b5;
  --primary-dark: #0034bb;
  --primary-darker: #00278b;
  --transparent: transparent;

  --primary-01: var(--midnight);
  --primary-02: var(--pure-white);
  --primary-ui: var(--blue);
  --neutral-00: var(--grey-00);
  --neutral-05: var(--grey-05);
  --neutral-10: var(--grey-10);
  --neutral-15: var(--grey-15);
  --neutral-20: var(--grey-20);
  --neutral-30: var(--grey-30);
  --neutral-50: var(--grey-50);
  --neutral-70: var(--grey-70);
  --neutral-80: var(--grey-80);
  --neutral-90: var(--grey-90);
  --neutral-100: var(--grey-100);
  --positive: var(--green);
  --positive-light: var(--green-light);
  --positive-dark: var(--green-dark);
  --negative: var(--red);
  --negative-light: var(--red-light);
  --negative-dark: var(--red-dark);
  --warning: var(--orange);
  --warning-light: var(--orange-light);
  --warning-dark: var(--orange-dark);
  --information-light: var(--blue-light);
  --information-dark: var(--blue-dark);

  --spring-font-size-11: 11px;

  --primary-button-background: var(--primary-ui);
  --primary-button-border: var(--transparent);
  --primary-text-color: var(--primary-02);

  --quick-view-button-background: var(--primary-02);
  --quick-view-button-border: var(--neutral-20);
  --quick-view-text-color: var(--primary-01);
}
