.toastContainer {
  @apply pointer-events-auto fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6 bg-gray-500 bg-opacity-75 transition-opacity;
}

button.closeButton {
  @apply flex bg-white focus:outline-none;
}

.closeButton svg {
  @apply opacity-70 hover:opacity-100;
}

.toastOverlay {
  @apply pointer-events-auto fixed inset-0 bg-spring-neutral-15 opacity-70 transition-opacity z-10;
}

.toastPosition {
  @apply fixed top-0 right-0 px-4 py-6 z-50 flex flex-col space-y-4 items-end;
}

.toastContent {
  @apply pointer-events-auto w-96 flex-shrink-0 overflow-visible rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5 z-20;
}

.toastContentPadding {
  @apply p-4;
}

.toastContentFlex {
  @apply flex items-start;
}

.toastContentIconContainer {
  @apply flex-shrink-0;
}

.toastContentIcon {
  @apply h-6 w-6 text-green-400;
}

.toastContentDescription {
  @apply ml-3 w-0 flex-1;
}
